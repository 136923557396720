// Scroll.js
$(window).on('popstate', function (e) {
  e.preventDefault();
  var target = window.location.href.split('#')[1];
  if (target != '' && target != undefined && document.getElementById(target) != null) {
    $('html, body')
      .stop()
      .animate({ scrollTop: $('#' + target).offset().top }, 500, 'swing', function () {
        window.location.hash = target;
      });
  }
});

$(document).ready(function () {
  SF_scripts();
});

function SF_scripts() {
  $(window).resize(function () {
    showMenuBtn();
  });

  $(window).trigger('resize');

  // open menu on mobile
  function showMenuBtn() {
    if ($(window).width() < 1199.98) {
      $('.open_menu').addClass('d-block');
      $('header nav').addClass('d-none');
      $('.navigation_mobile').removeClass('opened');
    } else {
      $('.open_menu').removeClass('d-block');
      $('header nav').removeClass('d-none');
      $('.navigation_mobile').removeClass('opened');
    }
  }

  $('.open_menu').click(function (event) {
    event.preventDefault();
    $('.navigation_mobile').addClass('opened');
  });

  $('.close_menu, header, section, footer, .navigation_mobile .inner a').click(function (event) {
    $('.navigation_mobile').removeClass('opened');
  });

  // Enable AOS plugin (blocks animations)
  if (typeof AOS !== 'undefined' && $('body').hasClass('SFG_body') === false) {
    AOS.init({
      easing: 'ease-out-cubic',
      once: true,
      offset: 50,
    });
    setTimeout(function () {
      if ($('.slick-initialized').length > 0) {
        AOS.refreshHard();
      }
    }, 200);
  }
} // SF_scripts end
